import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import moment from "moment";
interface Activity {
  action: string;
  dateTime: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  children?:React.ReactNode
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  mobileOpen:boolean;
  isShowProgressBar : boolean ;
  isShowCongratulation : boolean ;
  isClosing:boolean;
  isActiveRoute:string;
  recentActivities: Activity[]; 
  userInitials: string;  
  userFullName: string; 
  isLogoutModalOpen: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      mobileOpen:false,
      isShowProgressBar:false ,
      isShowCongratulation:false ,
      isClosing:false,
      isActiveRoute:'',
      recentActivities: [],
      userInitials: '',  
      userFullName: '', 
      isLogoutModalOpen: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    this.handleRecentActivityResponse(apiRequestCallId, message)
    this.handleUserProfileResponse(apiRequestCallId, message)
    // Customizable Area End
  }

  // Customizable Area Start
  getRecentActivityCallId: string = "";
  getUsrProfileCallId: string = "";

  async componentDidMount() {
    this.handleRecentActivity();  
    const isProgressBar = await getStorageData("isShowProgress");
    this.setState({'isShowProgressBar' : isProgressBar})
    this.getUserProfile(); 
    setTimeout(()=>{
   if(this.state.isShowProgressBar){ 
    this.setState({'isShowProgressBar' : false}) 
    this.setState({"isShowCongratulation" : true})
   }
    },3000)
  }
  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
    if(!prevState.recentActivities.length) {
      this.handleRecentActivity()
    }
  }
  navTo = (name: string) => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), name);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  getUserProfile = async() => {
    const authToken = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: authToken
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getUsrProfileCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getUserProfileEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleUserProfileResponse = (id: string, message:Message) => {
    if(id === this.getUsrProfileCallId){
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if(responseJson.error) return
      if(responseJson.errors && responseJson.errors[0]?.token) {
        this.redirectToSignInPage()
      }
      if(responseJson) {
        const firstName = responseJson.first_name;
        const lastName = responseJson.last_name;
       
        this.setState({
          userInitials: this.getUserInitials(firstName, lastName),
          userFullName: this.getFullUserName(firstName, lastName),
        });
      }
    }
  }

  getFullUserName = (firstName: string, lastName: string) => {
    const capitalize = (name: string) => name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
    return `${capitalize(firstName)} ${capitalize(lastName)}`;
  }

  getUserInitials(firstName: string, lastName: string): string {
    return `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`;
  }

  handleDrawerToggle(){
    if (!this.state.isClosing) {
      this.setState({ "mobileOpen": !this.state.mobileOpen});
    }
  }

  handleDrawerClose() {
    this.setState({ "isClosing": true,'mobileOpen':false });
  };

  handleDrawerTransitionEnd() {
    this.setState({ "isClosing": false });
  };
 
  handleRecentActivity = async() => {
    const authToken = await getStorageData("token");
    const header = {
      token: authToken
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    
    this.getRecentActivityCallId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getRecentActivityApi}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  redirectToSignInPage = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'EmailAccountLoginBlock');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  handleRecentActivityResponse = (id: string, message: Message) => {
    if (id === this.getRecentActivityCallId) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (responseJson.error || (responseJson.errors && responseJson.errors[0].token)) {
        this.redirectToSignInPage();
        return;
      }

      if (responseJson && Array.isArray(responseJson)) {
        const recentActivities = responseJson.map((activity: any) => ({
          action: this.mapActionToText(activity.action),
          dateTime: this.formatDateTime(activity.date_time)
        }));
        this.setState({ recentActivities });
      }
    }
  }

  mapActionToText = (action: string): string => {
    switch (action) {
      case "Profile Created":
        return "You have successfully created your profile";
      case "Profile Updated":
        return "You have updated your profile";
      case "Case Created":
        return "You submitted a new case at";
      case "Case Updated":
        return "Your case has been updated";
      case "Settings Updated":
        return "You have updated your settings";
      case "Contact Submitted":
        return "You have submitted a new contact form";
      default:
        return "Activity Performed";
    }
  }

  formatDateTime = (dateTimeStr: string): string => {
      const formattedDate = moment(dateTimeStr).format('MMMM DD, YYYY, h:mm A');
      return formattedDate;
  };

  handleLogoutModalOpen() {
    this.setState({ isLogoutModalOpen: true });
  }

  handleLogoutModalClose() {
    this.setState({ isLogoutModalOpen: false });
  }

  handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.setItem("Signin", JSON.stringify(true))
    this.redirectToSignInPage();
  }
  
  // Customizable Area End
}
