import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible, headLogo, sidePic, sidePic2 } from "./assets";
import  {setStorageData} from "../../../framework/src/Utilities";

export enum Verification {
  EMAIL,
  PHONE
}
export enum Relationship {
  SELECT_OPTION="Select an option",
  SPOUSE="Spouse",
  CHILD="Child",
  SELF="Self",
}
export const menuItem =  [
  { label: Relationship.SELECT_OPTION, value: Relationship.SELECT_OPTION },
  { label: Relationship.SELF, value: Relationship.SELF },
  { label: Relationship.SPOUSE, value: Relationship.SPOUSE },
  { label: Relationship.CHILD, value: Relationship.CHILD },
]
export const OTPMessages = {
  otpValidationError: "Incorrect code, please try again",
  codeSentSuccess: "The code was sent successfully",
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  head: string;
  sidePic: string;
  sidePic2: string;
  showPassword: boolean;
  showConfirmPassword: boolean;
  confirmPassword: string;
  isOpenTC:boolean;
  isOpenPolicy:boolean;
  TermsAndCondList: { id: number, description: string, created_at: string, title: string }[];
  PrivacyPolicyList: { id: number, description: string, created_at: string, title:string }[];
  inputErrors: {
    firstNameError: boolean,
    lastNameError: boolean,
    emailError: boolean,
    phoneError: boolean,
    passwordError: boolean,
    confirmPasswordError: boolean
  },
  errorMessages: {
    passwordErrorMessage: string;
    confirmPasswordErrorMessage: string;
  };
  phoneNumberAlreadyTakenError:string
  emailInvalidError:string;
  existingMember: boolean;
  verificationType: Verification;
  screen: "verify" | "success" | "salesforce";
  verification: "existing-member" | "non-existing-member" | "none";
  otpErrorMessages: Record<string, string>;
  resendOtpSent: boolean;
  otp: string;
  otpError: boolean;
  success: boolean;
  salesforce_accounts: {
    Id: string,
    Email: string,
    FirstName: string,
    LastName: string,
    relationShip: Relationship,
    error: boolean,
    error_message: string,
    borderColor: string
  }[]
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  signupAccountApiCallId: any;
  validationApiCallId: string = "";
  getTermsAndCondApiCallId: string = "";
  getPrivacyPolicyApiCallId: string = "";
  resendCodeCallId: string = "";
  otpVerifyApiCallId: string = "";
  checkForOtherAccountsCallId: string = "";
  addRolesCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;
  scrollPosition: number = 0;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      head: headLogo.default,
      sidePic: sidePic.default,
      sidePic2: sidePic2.default,
      showPassword: false,
      showConfirmPassword: false,
      confirmPassword: '',
      isOpenTC: false,
      isOpenPolicy: false,
      TermsAndCondList: [],
      PrivacyPolicyList: [],
      inputErrors: {
        firstNameError: false,
        lastNameError: false,
        emailError: false,
        phoneError: false,
        passwordError: false,
        confirmPasswordError: false
      },
      errorMessages: {
        passwordErrorMessage: "",
        confirmPasswordErrorMessage: "",
      },
      phoneNumberAlreadyTakenError:"",
      emailInvalidError:"",
      existingMember: false,
      verification: "none",
      verificationType: Verification.PHONE,
      screen: "verify",
      otpErrorMessages: OTPMessages,
      resendOtpSent: false,
      otp: "",
      otpError: false,
      success: false,
      salesforce_accounts: []
      
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) return;

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (!apiRequestCallId || !responseJson) return;

    switch (apiRequestCallId) {
      case this.signupAccountApiCallId:
        this.signupApi(responseJson);
        break;
      case this.getTermsAndCondApiCallId:
        this.setState({ TermsAndCondList: responseJson.data });
        break;
      case this.getPrivacyPolicyApiCallId:
        this.setState({ PrivacyPolicyList: responseJson.data });
        break;
      case this.otpVerifyApiCallId:
        this.handlePhoneOtpResponse(apiRequestCallId, message);
        break;
      case this.resendCodeCallId:
        this.handleResendCodeResponse(apiRequestCallId, message);
        break;
      case this.checkForOtherAccountsCallId:
        this.handleResponseForCheckForOtherAccount(apiRequestCallId, message);
        break;
      case this.addRolesCallId:
        this.handleResponseForAddRoles(apiRequestCallId, message);
        break;
      default:
        console.warn('Unhandled API call ID:', apiRequestCallId);
        break;
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.handleGetTermsAndConditions();
    this.handleGetPrivacyPolicy();
    window.scrollTo(0, 0);
    
    if(this.props.navigation.state.routeName === "/ExistingMemberRegistration") {
      this.setState({existingMember: true})
    }
  }

  handleResendCode = async() => {
    this.setState({otpError: false, resendOtpSent: true});
      const authToken = localStorage.getItem("token");
      const user = JSON.parse(sessionStorage.getItem("user") || "");
      const header = {
        "Content-Type": configJSON.contentTypeApiAddDetail,
        "token" : authToken
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      if(this.state.verificationType === Verification.PHONE) {
        const httpBody = {
          data: {
            full_phone_number: `+${user.phoneNumber}`
          }
        };
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.signupAPiEndPoint}/resend_mobile_otp`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(httpBody)
        );
      } else {
        const httpBody = {
          data: {
            email: user.email
          }
        };
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.signupAPiEndPoint}/resend_email_otp`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(httpBody)
        );
      }
  
      this.resendCodeCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypePutDetail
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleResendCodeResponse = async(id: string, message:Message) => {
    if(id === this.resendCodeCallId){
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if(responseJson.error) return
      if(responseJson.errors?.length) {
        this.setState(() => ({otpError: true}))
      }
      else if(responseJson.message) {
        this.setState({resendOtpSent: true});
        setTimeout(()=>{
          this.setState({
            resendOtpSent: false
          })
        },3000)
      }
    }
  }
  handleOtp = (otpnumber: string) => {
    this.setState({ otp: otpnumber, otpError: false });
  };
  handleOtpSubmit = async() => {
    if (!this.state.otp) {
      this.setState({ otpError: true });
    } else {
      const authToken = localStorage.getItem("token");
      const header = {
        "Content-Type": configJSON.contentTypeApiAddDetail,
        "token" : authToken
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      if(this.state.verificationType === Verification.EMAIL) {
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.emailOtpVerifyApi
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify({
            data: {
              email_otp: this.state.otp
            }
          })
        );
      } else {
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.otpVerifyApi
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify({
            data: {
              mobile_otp: this.state.otp
            }
          })
        );
      }
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeAddDetail
      );
  
      this.otpVerifyApiCallId = requestMessage.messageId;
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }
  handlePhoneOtpResponse = async (id: string, message:Message) => {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if(responseJson.errors?.length || responseJson.error) {
        this.setState({ otpError: true });

        const otpErrorMessage = responseJson.error || responseJson.errors[0];
        let otpValidationError = "";
        if (otpErrorMessage?.otp === "Invalid OTP") {
          otpValidationError = "Incorrect code, please try again";
        } 
        else if (otpErrorMessage === "OTP has expired, please request a new one") {
          otpValidationError = "Code has expired, please request a new one";
        } else {
          otpValidationError = otpErrorMessage;
        }
        this.setState({
          resendOtpSent: false,
          otpErrorMessages: {
            ...this.state.otpErrorMessages,
            otpValidationError,
          },
        });
        return;
      }

      if(responseJson.message && this.state.verificationType === Verification.PHONE) {
        this.setState({verificationType: Verification.EMAIL, otp: "", otpError: false, resendOtpSent: false})
      } else if (responseJson.meta?.message && this.state.verificationType === Verification.EMAIL) {
        this.checkForOtherAccounts()
      }
  }
  handleSelectInput = (id: string, value: any) => {    
    const updated_accounts = this.state.salesforce_accounts.map(item => {
      if(item.Id === id) {
        return {...item, relationShip: value}
      } return item
    })
    this.setState((prev) => ({
      salesforce_accounts: updated_accounts
    }))
  }
  checkForMultipleRoles = (acc_arr: any) => {    
    const map = new Map();
    let ids: string[] = [];

    for (const acc of acc_arr) {
      if (map.has(acc.relationShip)) {
        ids.push(acc.Id);
      } else {
        map.set(acc.relationShip, true);
      }
    }
    return ids;
  }
  handleSubmitRoles = () => {
    const roles = this.checkForMultipleRoles(this.state.salesforce_accounts);
    this.setState(prev => ({
      salesforce_accounts: prev.salesforce_accounts.map(item => ({
        ...item,
        error: false,
        borderColor: "#10B981"
      }))
    }))
    const notSelectedRoles = this.state.salesforce_accounts.filter(item => item.relationShip === Relationship.SELECT_OPTION)
    if(notSelectedRoles.length) {
      this.setState({
        salesforce_accounts: this.state.salesforce_accounts.map(item => ({
          ...item,
          error: notSelectedRoles.includes(item),
          error_message: "Please select an option",
          borderColor: notSelectedRoles.includes(item) ? "#EF4444" : "#10B981"
        }))
      })
      return
    }  
    if(roles.length) {
      this.setState(prev => ({
        salesforce_accounts: prev.salesforce_accounts.map(item => ({
          ...item,
          error: roles.includes(item.Id),
          error_message: `You are allowed to select '${item.relationShip}' once.`,
          borderColor: roles.includes(item.Id) ? "#EF4444" : "#10B981"
        }))
      }))
      return
    }

    const authToken = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: authToken
    };
    const httpBody = {
      data: this.state.salesforce_accounts.map(item => ({[item.Id]: item.relationShip.toLocaleLowerCase()}))
    }
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.addRolesCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.addRolesApi}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePatchDetail
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleResponseForAddRoles = async(id: string, message:Message) => {
    if(id === this.addRolesCallId){
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if(responseJson.error) return
      if(responseJson.errors?.length) return
      if (responseJson.accounts) {
        this.setState({ screen: "success" });
        setTimeout(()=>{
          this.phonenumberVerification("LandingPage");
        },3000);
      }
    }
  }
  checkForOtherAccounts = () => {
    const user = JSON.parse(sessionStorage.getItem("user") || "");
    const authToken = localStorage.getItem("token");
    const header = {
      token: authToken
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.checkForOtherAccountsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.listSalesforceUsersApi}?email=${user.email}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleResponseForCheckForOtherAccount = async(id: string, message:Message) => {
    if(id === this.checkForOtherAccountsCallId){
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if(responseJson.error) return
      if(responseJson.errors?.length) return
      else if(responseJson.message === "Account with this email not found") {
        this.phonenumberVerification("Home")
      } else if (responseJson.salesforce_accounts) {
        if (responseJson.salesforce_accounts.length === 1) {
          this.setState({ screen: "success" });
          setTimeout(()=>{
            this.phonenumberVerification("LandingPage");
          },3000);
        } else {
          this.setState({salesforce_accounts: responseJson.salesforce_accounts.map((item: any) => ({
            ...item,
            relationShip: Relationship.SELECT_OPTION,
            error: false,
            error_message: "",
            borderColor: "#94A3B8"
          })), screen: "salesforce"})
        }
      }
    }
  }
  formatPhoneString = (value: string) => {
    if(value){
      return `+${value.slice(0,1)} ${value.slice(1,4)} ${value.slice(4,7)} ${value.slice(7,)}`
    }
  }

  backTo = () => {
    this.props.navigation.goBack();
  }
  redirectTo = (screen: "verify" | "salesforce" | "non-existing-member") => {
    if(screen === "verify") {
      this.state.verificationType === Verification.EMAIL ? this.setState({verificationType: Verification.PHONE}) : this.phonenumberVerification("ExistingMemberRegistration")
    } else if(screen === 'salesforce') {
      this.setState({verificationType: Verification.EMAIL, screen: "verify"})
    } else if(screen === "non-existing-member") {
      localStorage.setItem("Signin", JSON.stringify(true))
      this.phonenumberVerification("Home")
    }
  }
  back2 = () => {
    if (this.state.verification === "existing-member"){
        this.setState({verification: "none"})
    }
    else {
        this.phonenumberVerification('Home')
    }
  }

  startVerification = () => {
    this.phonenumberVerification('ExistingMemberOTPInputAuth');
  }

  signupApi = (responseJson: any) => {
    if (responseJson.message) {
      this.handleSuccessResponse(responseJson);
    } else if (responseJson.error || responseJson.errors) {
      this.handleErrorResponse(responseJson);
    }
  };
  
  handleSuccessResponse = (responseJson: any) => {
    const { existingMember, email, phone } = this.state;
  
    if (!existingMember) {
      setStorageData("isFrom", "SignUp");
      this.phonenumberVerification('OTPInputAuth');
    } else {
      this.setState({ verification: "existing-member" });
      sessionStorage.setItem("user", JSON.stringify({
        email: email,
        phoneNumber: phone.split(" ").join("").slice(1)
      }));
    }
  
    const token = responseJson.token.token;
    setStorageData("token", token);
    localStorage.setItem("token", token);
  };
  
  handleErrorResponse = (responseJson: any) => {
    const { existingMember } = this.state;
  
    if (existingMember && responseJson.errors?.includes("Account with this email not found")) {
      this.setState({ verification: "non-existing-member" });
    } else {
      this.processErrors(responseJson.error || responseJson.errors[0]);
    }
  };
  
  processErrors = (error: any) => {
    let phoneNumberError = '';
    let emailError = '';
  
    if (typeof error === 'string') {
      if (error.includes("Invalid or Unrecognized Phone Number")) {
        phoneNumberError = "Full phone number Invalid or Unrecognized Phone Number";
      } else if (error.includes("domain does not exist")) {
        emailError = "Domain does not exist";
      }
    } else {
      if (error.account) {
        emailError = error.account;
      }
      if (error.full_phone_number) {
        phoneNumberError = "This phone number is already taken";
      }
    }
  
    this.setState({
      phoneNumberAlreadyTakenError: phoneNumberError,
      emailInvalidError: emailError,
      verification: "none"
    });
  };  

  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  createAccount(): boolean {
    if (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password) ||
      this.isStringNullOrBlank(this.state.reTypePassword)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }

    var phoneNumberError = this.validateCountryCodeAndPhoneNumber(
      this.state.countryCodeSelected,
      this.state.phone
    );

    if (phoneNumberError) {
      this.showAlert(configJSON.errorTitle, phoneNumberError);
      return false;
    }

    if (!this.isValidEmail(this.state.email)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    }

    if (!this.passwordReg.test(this.state.password)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorPasswordNotValid);
      return false;
    }

    if (this.state.password !== this.state.reTypePassword) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorBothPasswordsNotSame
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      full_phone_number: this.state.phone
    };

    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnSignUpProps = {
    onPress: () => this.createAccount()
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    }
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    }
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    }
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    }
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleconfirmPassword = () => {
    this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
  };

  handleOpenTermCondition = () => {
    // alert('hello');
    this.scrollPosition = window.scrollY;
    this.setState({ isOpenTC: true });
  }

  handleCloseTermCondition = () => {
    this.setState({ isOpenTC: false }, () => {
      setTimeout(() => {
        window.scrollTo(0, this.scrollPosition);
      }, 50); 
    });
  }
   
  handleOpenPolicy = () => {
    this.scrollPosition = window.scrollY;
    this.setState({ isOpenPolicy: true });
  }

  handleClosePolicy = () => {
    this.setState({ isOpenPolicy: false }, () => {
      setTimeout(() => {
        window.scrollTo(0, this.scrollPosition)
      }, 50)
    });
  }

  handleInput = (e: any) => {
    let { name, value } = e.target;

    switch(name) {
      case 'first name':
        this.handleNames(name,value);
        break;
      case 'last name':
        this.handleNames(name,value);
        break;
      case 'email address':
        this.handleNames(name,value);
        break;
      case 'phone number':
        this.handleValues(name,value);
        break;
      case 'password':
        this.handleValues(name,value);
        break;
      case 'confirm password':
        this.handleValues(name,value);
        break;
      default:
        break;
    }
  }

  handleNames = (name:string,value:string) => {
    switch(name) {
      case 'first name':
        if (/^[A-Za-z][a-zA-Z'-]*$/.test(value) && value !== "") {
          this.setState({ firstName: value });
          this.setState({ inputErrors:{...this.state.inputErrors,firstNameError:false} })
        }
          this.setState({ firstName: value });
        break;
      case 'last name':
        if (value !== '' && /^[A-Za-z][a-zA-Z'-]*$/.test(value)) {
          this.setState({ lastName: value });
          this.setState({ inputErrors:{...this.state.inputErrors,lastNameError:false} })
        }
          this.setState({ lastName: value });
        break;
      case 'email address':
        this.setState({emailInvalidError:""})
        if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value) && value !== '') {
          this.setState({ email: value });
          this.setState({ inputErrors:{...this.state.inputErrors,emailError:false} })
        }
          this.setState({ email: value });
        break;
      default:
          break;
    }
  }

  handleValues = (name:string, value:string) => {
    const passwordCriteria = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
    switch(name) {
      case 'phone number':
        this.setState({phoneNumberAlreadyTakenError:""})
        if (/^(\+1\s?)?(\(?\d{3}\)?[\s.-]?)?\d{3}[\s.-]?\d{4}$/.test(value) || value === '') {
          this.setState({ inputErrors:{...this.state.inputErrors,phoneError:false}})
        }
        this.setState({ phone: `+1${value}` });
        break;
      case 'password':
        this.setState({ password: value }, () => {
          const { password, confirmPassword } = this.state;
          const passwordError = !(passwordCriteria.test(password)) || password === '';
          const confirmPasswordError = password !== confirmPassword;
          
          this.setState({
            inputErrors: {
              ...this.state.inputErrors,
              passwordError,
              confirmPasswordError
            },
            errorMessages: {
              ...this.state.errorMessages,
              passwordErrorMessage: passwordError ? configJSON.errorPasswordMessage : '',
              confirmPasswordErrorMessage: confirmPasswordError ? configJSON.errorConfirmPasswordMessage : ''
            }
          });
        });
        break;
      case 'confirm password':
        this.setState({ confirmPassword: value }, () => {
          const { password, confirmPassword } = this.state;
          const confirmPasswordError = password !== confirmPassword;
          
          this.setState({
            inputErrors: {
              ...this.state.inputErrors,
              confirmPasswordError
            },
            errorMessages: {
              ...this.state.errorMessages,
              confirmPasswordErrorMessage: confirmPasswordError ? configJSON.errorConfirmPasswordMessage : ''
            }
          });
        });
        break;
      default:
        break;
    }
  }

  validateName = (name: string, maxLength: number) => {
    const namePattern = /^[A-Za-z][a-zA-Z'-]*$/;
    return namePattern.test(name) && name !== "" && name.length <= maxLength;
  };
  
  validateEmail = (email: string) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email) && email !== "" && email.length <= 64;
  };
  
  validatePhone = (phone: string) => {
    const phonePattern = /^(\+1\s?)?(\(?\d{3}\)?[\s.-]?)?\d{3}[\s.-]?\d{4}$/;
    return phonePattern.test(phone) && phone !== "";
  };
  
  validatePassword = (password: string) => {
    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
    return passwordPattern.test(password) && password !== "";
  };
  
  handleSignUp = async () => {
    let errorObj = {
      firstNameError: false,
      lastNameError: false,
      emailError: false,
      phoneError: false,
      passwordError: false,
      confirmPasswordError: false,
    };
  
    let errorMessages = {
      passwordErrorMessage: "",
      confirmPasswordErrorMessage: "",
    };
  
    const { existingMember, firstName, lastName, email, phone, password, confirmPassword, countryCodeSelected } = this.state;
  
    if (!existingMember) {
      errorObj.firstNameError = !this.validateName(firstName, 64);
      errorObj.lastNameError = !this.validateName(lastName, 64);
    }
  
    errorObj.emailError = !this.validateEmail(email);
    errorObj.phoneError = !this.validatePhone(phone);
  
    if (!this.validatePassword(password)) {
      errorObj.passwordError = true;
      errorMessages.passwordErrorMessage = configJSON.errorPasswordMessage;
    }
  
    if (password !== confirmPassword || confirmPassword === "") {
      errorObj.confirmPasswordError = true;
      errorMessages.confirmPasswordErrorMessage = configJSON.errorConfirmPasswordMessage;
    }
  
    this.setState({ inputErrors: { ...errorObj }, errorMessages });
  
    if (this.anyErrors(errorObj)) {
      return 0;
    }
  
    await this.storeUserData(phone, email);
    await this.makeSignUpRequest({
      firstName,
      lastName,
      email,
      password,
      fullPhoneNumber: countryCodeSelected + phone,
      existingMember,
    });
  
    return true;
  };
  
  storeUserData = async (phone: string, email: string) => {
    await setStorageData("phoneNumber", phone);
    await setStorageData("emailAddress", email);
  };
  
  makeSignUpRequest = async (userData: any) => {
    const header = { "Content-Type": configJSON.contentTypeApiAddDetail };
    const data = {
      type: "email_account",
      is_salesforce_exist: userData.existingMember,
      attributes: {
        first_name: userData.firstName,
        last_name: userData.lastName,
        email: userData.email,
        password: userData.password,
        full_phone_number: userData.fullPhoneNumber,
      },
    };
  
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.signupAccountApiCallId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.signupAPiEndPoint
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ data })
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  

  handleGetTermsAndConditions = () => {
    const header = {};

    const termConditionMes = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    termConditionMes.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.termsAndCondEndPoint
    );

    termConditionMes.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    termConditionMes.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    this.getTermsAndCondApiCallId = termConditionMes.messageId;

    runEngine.sendMessage(termConditionMes.id, termConditionMes);
  }

  handleGetPrivacyPolicy = () => {
    const header = {};

    const privacyPolicyMes = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    privacyPolicyMes.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.privacyPolicyEndPoint
    );

    privacyPolicyMes.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    privacyPolicyMes.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    this.getPrivacyPolicyApiCallId = privacyPolicyMes.messageId;

    runEngine.sendMessage(privacyPolicyMes.id, privacyPolicyMes);
  }

  anyErrors = (errorObj: any) => {
    for (let arr of Object.entries(errorObj)) {
      let val = arr[1]
      if (val === true) {
        return true;
      }
    }
  }

  phonenumberVerification = (name:string) => {
    localStorage.setItem("Signin", JSON.stringify(true))
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      name
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message);
  }

  

  // Customizable Area End
}
