import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets"; 
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  formValues :{
      firstName: string,
      lastName: string,
      cardNumber: string,
      expirationDate: string,
      cvc: string,
      addressLine1: string,
      addressLine2: string,
      city: string,
      state: string,
      zipCode: string,
  }
  loading:boolean;
   subscriptionPlan : any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Subscriptionbilling2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getSubscriptionPlanApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      formValues :{
        firstName: '',
        lastName: '',
        cardNumber: '',
        expirationDate: '',
        cvc: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zipCode: '',
        },
      loading: false,
      subscriptionPlan:{}
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
   
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (responseJson.status === 500) {
          toast.error("Something went wrong!");
          this.setState({ loading: false });
        } else if (responseJson.errors) {
          toast.error(responseJson.errors);
          this.setState({ loading: false });
        } else if (apiRequestCallId == this.getSubscriptionPlanApiCallId) {
           this.setState({'subscriptionPlan' : responseJson.subscription_plan })
        }
      } 
      this.setState({ loading: false });
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() { 
    this.getSubscriptionPlan()
    window.scrollTo(0, 0);
   }
 
   getSubscriptionPlan = ()=>{  
    this.setState({ loading: true });
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSubscriptionPlanApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      // configJSON.getSubscriptionPlanApiEndPoint
      '/bx_block_subscription_billing/subscription_plan/show_active_plan'
        );
   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSubscriptionPlanApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ loading: false });
  };

  formatCardNumber = (value: string) => {
    const cleaned = value.replace(/\D+/g, '');
    const formatted = cleaned.match(/.{1,4}/g)?.join('-') || cleaned;
    
    return formatted;
  };

  handleCardNumberChange = (e:any, setFieldValue: any) => {
    const formattedCardNumber = this.formatCardNumber(e.target.value);
    setFieldValue('cardNumber', formattedCardNumber);
  };
 
  formatExpirationDate = (value: string) => {
    const cleaned = value.replace(/\D+/g, ''); 
    let formatted = cleaned;

    if (cleaned.length > 2) {
      formatted = `${cleaned.slice(0, 2)}/${cleaned.slice(2, 4)}`;
    }
    return formatted;
  };

  handleExpirationDateChange = (e: any, setFieldValue: Function) => {
    const formattedExpirationDate = this.formatExpirationDate(e.target.value);
    setFieldValue('expirationDate', formattedExpirationDate);
  }; 

  handleTextTypeValues = (e: any, setFieldValue: Function , field:string) => {
    const formattedTextValue = e.target.value.replace(/[^A-Za-z]/g, '');
    setFieldValue(field, formattedTextValue);
  }; 

  handleNumberTypeValues = (e: any, setFieldValue: Function , field:string) => {
    const formattedNumberValue = e.target.value.replace(/[^\d]/g, '');
    setFieldValue(field, formattedNumberValue);
  }; 

  // Customizable Area End
}
