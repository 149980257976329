import React from 'react'
import { Stack, Typography, Box, Divider } from "@mui/material"
import { validCard, visaCard } from '../assets';

const SubscriptionBilling = ({onClickUpdate}: {onClickUpdate: any})=> {
   
    return (
       <Box sx={styles.wrapper}>
          <Box sx={styles.innerWrapper}>
             <Typography style={styles.heading}>Your payment method</Typography>
             <Box 
             sx={{ 
                width: {xs: '100%', sm: '399px', md: '452px', lg: '452px'}, 
                border: '1px solid #94A3B8', 
                borderRadius: '10px', 
                marginTop: '10px' 
             }}>
               <Box sx={{ padding: '22px 30px'}}>
                 <Box sx={{display: 'flex', gap: {xs:'26px', sm: '26px', md: '42px', lg: '42px'}}}>
                    <Box>
                        <img src={visaCard.default} alt='visa img'/>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '11px'}}>
                        <Typography sx={styles.cardNum}>**** **** **** 5678</Typography>
                        <Typography sx={styles.cardTxt}>Next payment date: <Typography component='span' sx={styles.cardDate}>02.11.2024</Typography> </Typography>
                    </Box>
                 </Box>
               </Box>
               <Divider variant="middle" sx={{ borderColor: '#94A3B8' }} />
               <Box sx={{ padding: '22px 30px', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                 <img src={validCard.default} alt='Valid'/>
                 <Typography style={styles.clickTxt} onClick={() => onClickUpdate("update-payment", true)}>Update</Typography>
               </Box>
             </Box>
          </Box>
          <Box>
            <Stack> 
              <Typography style={styles.heading}>Your Subscription</Typography>
               <ul>
                  <li style={{marginBottom: '6px'}}>
                    <Typography style={styles.listTxt}>$99/year Plan</Typography>
                  </li>
                  <li>
                    <Typography style={styles.listTxt}>
                        Renews on: <span style={styles.date}>12/03/2024</span>
                    </Typography>
                  </li>
               </ul>
               <Typography style={styles.clickTxt}>Cancel plan</Typography>
            </Stack>
              
          </Box>
       </Box>
    )
}

const textStyles = {
    fontFamily: 'Inter',
    fontSize: '16px',
    color: '#334155',
    lineHeight: '24px',
};

const cardTextStyles = {
    fontFamily: 'Inter',
    fontSize: {xs:'10.5px', sm:'14px', md: '14px', lg: '14px'},
    color: '#334155',
    lineHeight: '22px',
}

const styles = {
    wrapper: {
       display: 'flex',
       flexDirection: 'column',
       gap: '30px'
    },
    innerWrapper: {
       marginTop: {xs: '30px', sm: '30px', md: '15px', lg: '0px'}
    },
    heading :{
        ...textStyles,
        fontWeight: 700,
    },
    listTxt: {
        ...textStyles,
        fontWeight : 400,
    },
    date: {
        ...textStyles,
        fontSize: '14px',
        fontWeight : 700,
        lineHeight: '22px',
    },
    clickTxt: {
        ...textStyles,
        fontSize: '14px',
        fontWeight : 400,
        lineHeight: '22px',
        textDecoration: 'underline'
    },
    cardNum:{ 
        fontFamily: 'Inter',
        fontSize: {xs: '16px', sm: '18px', md: '18px', lg:'18px'},
        color: '#0F172A',
        lineHeight: '26px',
        fontWeight : 700,
    },
    cardTxt: {
        ...cardTextStyles,
        fontWeight: 400,
    },
    cardDate: {
        ...cardTextStyles,
        fontWeight : 700,
    }

};

export default SubscriptionBilling