// Customizable Area Start
import React from "react";
import Subscriptionbilling2Controller, {
    Props,
} from "./Subscriptionbilling2Controller";
import { Box, Card, CardContent, Stack, styled, Button, Typography, Grid } from '@mui/material';
import Layout from "../../landingpage/src/Layout.web";
import { logoIcon , visaCard } from './assets';
import {setStorageData } from "../../../framework/src/Utilities";

const LogoIcon = styled('img')({
    width: '40px'
});

export default class PaymentConfirmation extends Subscriptionbilling2Controller {
    constructor(props: Props) {
        super(props);

    }

    render() {
        return (
            <Layout id="" navigation={this.props.navigation}>
                <Box sx={{ margin: 'auto', padding: '20px', fontFamily: 'Inter' }}>
                    <Typography variant="h5" sx={{ fontFamily: 'Inter', fontSize: '24px', fontWeight: 700 }} gutterBottom>
                        Subscription Billing
                    </Typography>
                    <Typography variant="h6" sx={{ fontFamily: 'Inter', fontSize: '20px', fontWeight: 700, color: '#475569', paddingTop: '30px' }}>Payment Confirmation</Typography>
                    <Typography sx={{ fontFamily: 'Inter', fontSize: '20px', fontWeight: 700, color: '#475569', paddingTop: '20px', display: 'flex', justifyContent: 'center' }}>Do you confirm your purchase?</Typography>
                    <Box sx={{paddingLeft:{sx:'0px' , sm:'180px'}, paddingRight:{sx:'0px' , sm:'180px'} , paddingTop:'20px'}}>
                        <Grid container spacing={3}>
                            <Grid item  xs={12} sm={5} >
                                <Typography sx={{ fontFamily: 'Inter', fontSize: '16px', fontWeight: 700, color: '#475569' }}>Your payment method</Typography>
                                <Box sx={{display:'flex', justifyContent:'center', alignItems:'center' , gap:5 , borderRadius:'16px' , border: '1px solid grey'}}> 
                                    <img
                                        src={visaCard.default}
                                        alt="Card icon"
                                        style={{ width: '67px', height: '39px' }}
                                    />
                                    <p style={{fontSize: '18px', fontWeight: 700, fontFamily: 'Inter' , color:'#0F172A'}}>**** **** **** 2917</p>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography sx={{ fontFamily: 'Inter', fontSize: '16px', fontWeight: 700, color: '#475569' }}>Your subscription plan</Typography>
                                <Box sx={{ display: {sm:'flex' , xs:'block'},marginTop: '50px' ,alignItems: 'center' , justifyContent: 'center' }}>
                           <Box sx={{ width:{xs:'90%' ,sm:'444px'} }}>
                            <Box style={{border :'grey 1px solid', 
                                 borderRadius: '16px',padding: '8px' }}  >
                                <Box display="flex" justifyContent="center" marginBottom="16px">
                                 <Stack sx={{ ml: 1,paddingLeft: '25px', display: { width: '80px' } }}>
                                        <LogoIcon src={logoIcon.default} />
                                </Stack>
                                </Box>
                                <h3 style={{ textAlign: 'center', margin: '8px 0', fontSize: '24px', fontWeight: 400, fontFamily: 'Inter' }}>
                                { this.state.subscriptionPlan.title	}
                                    </h3>
                                <p style={{ textAlign: 'center', margin: '8px 0', fontSize: '14px', fontWeight: 400, fontFamily: 'Inter' }}>{ this.state.subscriptionPlan.sub_title} </p>

                                <Box  sx={{paddingLeft: '20px'}} margin='10px' >
                                        <div dangerouslySetInnerHTML={{ __html : this.state.subscriptionPlan.description}} style={{ fontFamily: 'Inter', marginBottom: '6px' ,fontSize: '16px', fontWeight: 400, color: '#334155' }}></div>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                            </Grid>
                        </Grid>
                        <Box sx={{display: 'flex',alignItems: 'center', justifyContent: 'center', }} >
                        <Box sx={{borderBottom:'1px solid gray', height:'65px',display: 'flex' , justifyContent:'space-between' ,  paddingLeft:'20px' , paddingRight:'20px',width:{sm:'88%' , xs:'100%'} }} >
                        <p style={{ fontSize: '20px', fontWeight: 700, fontFamily: 'Inter' }}>
                        In Total:
                        </p>
                        <p style={{ fontSize: '24px', fontWeight: 700, fontFamily: 'Inter' }}>
                            $99
                        </p>
                        </Box>
                        </Box>
                    </Box>
                    <Box sx={{display:'flex', justifyContent:'center' , paddingTop:'40px'}}>
                    <Box sx={{ display:{sm:'flex' , xs:'block'},width:{sm:'50%' , xs:'100%'}, gap:8 }}>
                    <Button 
                       data-test-id='submitBtn'
                      fullWidth
                      onClick={()=>{
                        setStorageData('isShowProgress' , true) 
                        this.props.navigation.navigate('LandingPage')
                       } }
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{
                          background: 'linear-gradient(90deg, #f7b733, #dd8723)',
                          fontWeight: 600,
                          textTransform: 'none',
                          fontFamily: 'Inter',
                        }}
                      >
                        Confirm
                      </Button>
                      <Button 
                      data-test-id='cancelBtn'
                      onClick={()=>{this.props.navigation.navigate('AddSubscription')}}
                        fullWidth
                        variant="contained"
                        sx={{
                          background: '#f1f4f9',
                          marginTop:{xs:'20px' ,sm:'0px'},
                          fontWeight: 600,
                          textTransform: 'none',
                          fontFamily: 'Inter',
                          color: '#788393',
                          ":hover": {
                            background: '#f1f4f9',
                          },
                        }}
                      >
                        Cancel
                      </Button>
                    </Box>
                    </Box>
                </Box>
            </Layout>
        );
    }
}

// Customizable Area End
